import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import qs from "qs";

import New from "./New";
import Assignment from "./Assignment";

function App() {
  return (
    <Routes>
      <Route path="/" element={<DefaultAssignment/>} />
      <Route path="/:encoded" element={<AssignmentDecode/>} />
      <Route path="/new" element={<New/>} />
    </Routes>
  );
}

const DefaultAssignment = () => (
  <Assignment text="lol lmao" count={5} />
);

function AssignmentDecode() {
  const { encoded } = useParams();
  const decoded = atob(encoded);
  const assignment = qs.parse(decoded);
  return <Assignment text={assignment.text} count={assignment.count} />;
}

export default App;
